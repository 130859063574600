import React, { useEffect, useState } from "react";
import apple from "../../images/apple.webp";
import android from "../../images/android.png";
import logo from "../../images/avtoe-bg.png";
import { useFetch } from "../../api/index";
import { slide as Menu } from "react-burger-menu";
import { SocialIcon } from "react-social-icons";
import burger from "../../images/burger.png";
import { PulseLoader } from "react-spinners";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import { useTheme } from "../../context/ThemeContext";
import { IoHomeOutline } from "react-icons/io5";
import { FaRegNewspaper } from "react-icons/fa";
import { MdOutlineArticle } from "react-icons/md";
import { FiYoutube } from "react-icons/fi";
import { Link } from "react-router-dom";

const Header = ({ toggleDarkMode, isDarkMode }) => {
  const { theme, toggleTheme } = useTheme("light");
  const [activeLink, setActiveLink] = useState("");
  useEffect(() => {
    // Get the current path and set it as activeLink
    const currentPath = window.location.pathname;
    setActiveLink(currentPath);
  }, []);
  const handleClick = (link) => {
    setActiveLink(link);
  };
  const { articles, loading, error, news } = useFetch(
    "https://admin.avtoe.news/api/articles?populate=Img"
  );

  if (loading) return <PulseLoader color="#0a28b7" />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <div className="container-menu-desktop">
        {/* topbar desctop */}
        <div className="topbar">
          <div className="left-topbar">
            {/* {weather} */}
            <Menu customBurgerIcon={<img src={burger} />}>
              <div>
                <a
                  id="home"
                  className="menu-item mt-5 d-flex align-items-center"
                  href="/"
                >
                  <IoHomeOutline size={30} />

                  <span className="left-topbar-item">Головна</span>
                </a>
                <a
                  id="news"
                  className="menu-item  d-flex align-items-center mt-2"
                  href="/news"
                >
                  <FaRegNewspaper size={30} />
                  <span className="left-topbar-item">Новини</span>
                </a>
                <a
                  id="articles"
                  className="menu-item d-flex align-items-center mt-2"
                  href="/articles"
                >
                  <MdOutlineArticle size={30} />
                  <span className="left-topbar-item">Статті</span>
                </a>
                <a
                  id="videos"
                  className="menu-item d-flex align-items-center mt-2"
                  href="/videos"
                >
                  <FiYoutube size={30} />
                  <span className="left-topbar-item">Ми в YouTube</span>
                </a>
              </div>
              <div className="p-2 mb-4 d-flex justify-content-center">
                <a href="#" className="me-2">
                  <SocialIcon
                    url="https://www.youtube.com/@user-so1jb9wi3t"
                    style={{ width: "40px", height: "40px" }}
                  />
                </a>
                <a href="#" className="me-2">
                  <SocialIcon
                    url="https://www.facebook.com/avtoeukraine/"
                    style={{ width: "40px", height: "40px" }}
                  />
                </a>
                <a href="#" className="me-2">
                  <SocialIcon
                    url="https://www.instagram.com/avtoe.ua/"
                    style={{ width: "40px", height: "40px" }}
                  />
                </a>

                <a href="https://apps.apple.com/ua/app/avtoe/id6450632591">
                  <img src={apple} width="40" className="me-2" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.avtoe&hl=uk&gl=US">
                  <img src={android} width="40" className="" />
                </a>
              </div>
            </Menu>
          </div>
          <div className="d-flex align-items-center">
            <a href="/">
              <img
                src={logo}
                alt="logo"
                width={100}
                className="logoMobile mobile-pad-img"
              />
            </a>

            <DarkModeSwitch
              className="modeMobile switch-mode me-5"
              checked={isDarkMode}
              onChange={toggleDarkMode}
              size={30}
              color="#fff"
            />
            <DarkModeSwitch
              className="modeMobile switch-mode"
              checked={!isDarkMode}
              onChange={toggleDarkMode}
              size={30}
              color="#fff"
            />
          </div>

          <div className="content-topbar container h-100 d-flex justify-between text-center">
            <>
              <li className="left-topbar">
                {" "}
                <a className="logoMain-wrapper" href="/">
                  <img src={logo} alt="LOGO" className="logoMain" />
                </a>
              </li>
              <div className="d-flex align-center">
                <DarkModeSwitch
                  checked={isDarkMode}
                  onChange={toggleDarkMode}
                  size={40}
                  className="switch-mode me-3"
                />
                <DarkModeSwitch
                  checked={!isDarkMode}
                  onChange={toggleDarkMode}
                  size={40}
                  className="switch-mode"
                />
              </div>
              <li className="right-topbar">
                <a href="#">
                  <SocialIcon
                    url="https://www.youtube.com/@user-so1jb9wi3t"
                    style={{ width: "30px", height: "30px" }}
                  />
                </a>
                <a href="#">
                  <SocialIcon
                    url="https://www.facebook.com/avtoeukraine/"
                    style={{ width: "30px", height: "30px" }}
                  />
                </a>
                <a href="#">
                  <SocialIcon
                    url="https://www.instagram.com/avtoe.ua/"
                    style={{ width: "30px", height: "30px" }}
                  />
                </a>

                <a href="https://apps.apple.com/ua/app/avtoe/id6450632591">
                  <img src={apple} width="30" className="me-2 ms-1" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.avtoe&hl=uk&gl=US">
                  <img src={android} width="30" />
                </a>
              </li>
            </>
          </div>
        </div>

        {/* mobile */}

        {/* main menu desktop */}
        <div className="wrap-main-nav">
          <div className="main-nav">
            <nav className="menu-desktop">
              <ul className="main-menu">
                <li className="main-menu-item">
                  <a
                    href="/"
                    className={activeLink === "/" ? "active-link" : ""}
                    onClick={() => handleClick("/")}
                  >
                    Головна
                  </a>
                </li>

                <li className="mega-menu-item">
                  <a
                    href="/news"
                    className={activeLink === "/news" ? "active-link" : ""}
                    onClick={() => handleClick("news")}
                  >
                    Новини
                  </a>

                  {/* <div className="sub-mega-menu">
                    <div className="nav flex-column nav-pills" role="tablist">
                      <a
                        className="nav-link submenu-link"
                        data-toggle="pill"
                        href="/news"
                        role="tab"
                      >
                        Всі
                      </a>
                      <a
                        className="nav-link"
                        data-toggle="pill"
                        href="/news"
                        role="tab"
                      >
                        Бізнес
                      </a>
                      <a
                        className="nav-link"
                        data-toggle="pill"
                        href="/news"
                        role="tab"
                      >
                        Авто
                      </a>
                      <a
                        className="nav-link"
                        data-toggle="pill"
                        href="/news"
                        role="tab"
                      >
                        Зовнішній ринок
                      </a>
                    </div>

                    <div className="tab-content">
                      <div
                        className="tab-pane show active"
                        id="news-0"
                        role="tabpanel"
                      >
                        <div className="row">
                          {news?.data.map((item) => {
                            const imgURL =
                              item.attributes.Img.data.attributes.url;
                            return (
                              <div className="col-3" key={item.id}>
                                <div>
                                  <a
                                    href={`/news/${item.id}`}
                                    className="wrap-pic-w hov1 trans-03"
                                  >
                                    <img
                                      src={`https://admin.avtoe.news${imgURL}`}
                                      alt="IMG"
                                      width={100}
                                    />
                                  </a>

                                  <div className="p-t-10">
                                    <h5 className="p-b-5">
                                      <a
                                        href={`/news/${item.id}`}
                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                      >
                                        {item.attributes.Title}
                                      </a>
                                    </h5>

                                    <span className="cl8">
                                      <a
                                        href="#"
                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                      >
                                        {item.attributes.Category}
                                      </a>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {articles?.data.slice(1, 3).map((article) => {
                        const imgURL =
                          article.attributes.Img.data.attributes.url;
                        return (
                          <div
                            className="tab-pane"
                            id="news-1"
                            role="tabpanel"
                            key={article.id}
                          >
                            <div className="row">
                              <div className="col-3">
                                <div>
                                  <a
                                    href={`/articles/${article.id}`}
                                    className="wrap-pic-w hov1 trans-03"
                                  >
                                    <img src={imgURL} alt="IMG" />
                                  </a>

                                  <div className="p-t-10">
                                    <h5 className="p-b-5">
                                      <a
                                        href={`/articles/${article.id}`}
                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                      >
                                        {article.attributes.Title}
                                      </a>
                                    </h5>

                                    <span className="cl8">
                                      <a
                                        href="#"
                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                      >
                                        {article.attributes.Category}
                                      </a>

                                      <span className="f1-s-3 m-rl-3">-</span>

                                      <span className="f1-s-3">
                                        {new Date(
                                          `${article.attributes.createdAt}`
                                        ).toLocaleString("uk-UA", {
                                          month: "short",
                                          day: "numeric",
                                          year: "numeric"
                                        })}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div> */}
                </li>

                <li className="mega-menu-item">
                  <a
                    href="/articles"
                    className={activeLink === "/articles" ? "active-link" : ""}
                    onClick={() => handleClick("articles")}
                  >
                    Статті
                  </a>

                  {/* <div className="sub-mega-menu">
                    <div className="nav flex-column nav-pills" role="tablist">
                      <a
                        className="nav-link"
                        data-toggle="pill"
                        href="/articles"
                        role="tab"
                      >
                        Всі
                      </a>
                      <a
                        className="nav-link"
                        data-toggle="pill"
                        href="articlesСategory?category=Авто"
                        role="tab"
                      >
                        Авто
                      </a>
                      <a
                        className="nav-link"
                        data-toggle="pill"
                        href="articlesСategory?category=Бізнес"
                        role="tab"
                      >
                        Бізнес
                      </a>
                      <a
                        className="nav-link"
                        data-toggle="pill"
                        href="articlesСategory?category=Зовнішній%20ринок"
                        role="tab"
                      >
                        Зовнішній ринок
                      </a>
                    </div>

                    <div className="tab-content">
                      <div
                        className="tab-pane show active"
                        id="business-1"
                        role="tabpanel"
                      >
                        <div className="row">
                          {articles.data.slice(1, 3).map((article) => {
                            const imgURL =
                              article.attributes.Img.data.attributes.url;
                            return (
                              <div className="col-3" key={article.id}>
                                <div>
                                  <a
                                    href={`/articles/${article.id}`}
                                    className="wrap-pic-w hov1 trans-03"
                                  >
                                    <img
                                      src={`https://admin.avtoe.news${imgURL}`}
                                      alt="IMG"
                                    />
                                  </a>

                                  <div className="p-t-10">
                                    <h5 className="p-b-5">
                                      <a
                                        href={`/articles/${article.id}`}
                                        className="f1-s-5 cl3 hov-cl10 trans-03"
                                      >
                                        {article.attributes.Title}
                                      </a>
                                    </h5>

                                    <span className="cl8">
                                      <a
                                        href="/"
                                        className="f1-s-6 cl8 hov-cl10 trans-03"
                                      >
                                        {article.attributes.Category}
                                      </a>

                                      <span className="f1-s-3 m-rl-3">-</span>

                                      <span className="f1-s-3">
                                        {" "}
                                        {new Date(
                                          `${article.attributes.createdAt}`
                                        ).toLocaleString("uk-UA", {
                                          month: "short",
                                          day: "numeric",
                                          year: "numeric"
                                        })}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div> */}
                </li>

                <li className="mega-menu-item">
                  <a
                    href="/videos"
                    className={activeLink === "/videos" ? "active-link" : ""}
                  >
                    Краще з YouTube
                  </a>

                  {/* <div className="sub-mega-menu">
                    <div className="nav flex-column nav-pills" role="tablist">
                      <a
                        className="nav-link active"
                        data-toggle="pill"
                        href="/videos"
                        role="tab"
                      >
                        Всі відео
                      </a>
                    </div>
                  </div> */}
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
