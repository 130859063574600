import React from "react";
import notFound from "../../images/404.jpg";

const NotFound = () => {
  return (
    <div className="animsition">
      <div className="bg0 p-t-70">
        <div className="container">
          <div className="row justify-content-center mobile-flex-reverse">
            <div className="col-md-10 col-lg-8">
              <div className="p-b-20 mt-5 ">
                <img alt="404" src={notFound} width="100%" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
