import { useEffect, useState } from "react";

export const useFetch = (url) => {
  const [articles, setArticles] = useState(null);
  const [comments, setCommnets] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(url);
        const response1 = await fetch(url);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const data2 = await response1.json();
        setArticles(data);
        setCommnets(data2);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url]);

  return { articles, comments, loading, error };
};
