import React from "react";
import Articles from "../Articles";
import Popular from "../Popular";
import { useTheme } from "../../context/ThemeContext";

const AllArticles = () => {
  const { theme, toggleTheme } = useTheme("light");
  return (
    <div className={`animsition ${theme === "dark" ? "dark" : ""}`}>
      <div className="bg0 p-t-70">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8">
              <div className="p-b-20">
                <Articles />
              </div>
            </div>

            <div className="col-md-10 col-lg-4 mt-5">
              <Popular />
              {/* <Subscribe /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllArticles;
