import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Popular from "../Popular";
import { useFetch } from "../../api/index";
import Markdown from "react-markdown";
import moment from "moment";
import {
  CommentText,
  CommentMetadata,
  CommentGroup,
  CommentContent,
  CommentAvatar,
  CommentActions,
  CommentAction,
  CommentAuthor,
  FormTextArea,
  Button,
  Comment,
  Form,
  Header
} from "semantic-ui-react";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon
} from "react-share";
import AvtoeApp from "../AvtoeApp";
import { useTheme } from "../../context/ThemeContext";

const NewsDetails = () => {
  const { theme, toggleTheme } = useTheme("light");

  const { id } = useParams();
  const { articles } = useFetch(`https://admin.avtoe.news/api/news/${id}`);
  const { comments } = useFetch(
    `https://admin.avtoe.news/api/comments/api::news.news:${id}`
  );

  const [body, setBody] = useState("");
  const [username, setUserName] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [dateAdded, setDateAdded] = useState(null);
  const [commentsfetched, setCommentsData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editContent, setEditContent] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleEditClick = (commentId, currentContent) => {
    setEditingCommentId(commentId);
    setEditContent(currentContent);
    setIsEditing(true);
  };
  const handleEditChange = (e) => {
    setEditContent(e.target.value);
  };
  const handleChangeComment = (e) => {
    setBody(e.target.value);
  };
  const handleChangeUsername = (e) => {
    setUserName(e.target.value);
  };
  const now = moment.utc();

  // Convert to ISO string
  const isoDate = now.toISOString();

  // Format the date as "MM/DD/YYYY"
  const formattedDate = now.format("MM/DD/YYYY");

  const fetchComments = async () => {
    const response = await fetch(
      `https://admin.avtoe.news/api/comments/api::news.news:${id}`,
      {
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzE4OTc1NjgzLCJleHAiOjE3MjE1Njc2ODN9.ndHmLPd1ktovK28N1W9NZkMf7M9GQfiCD78QtrprY8E"
        },
        method: "GET"
      }
    );
    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }
    const data = await response.json();
    setCommentsData(data);
  };

  const usePost = async () => {
    await fetch(`https://admin.avtoe.news/api/comments/api::news.news:${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzE4OTc1NjgzLCJleHAiOjE3MjE1Njc2ODN9.ndHmLPd1ktovK28N1W9NZkMf7M9GQfiCD78QtrprY8E"
      },
      method: "POST",
      body: JSON.stringify({
        author: {
          id: `${id}`,
          name: username,
          email: "oksisukh@yahoo.com"
        },
        content: body
      })
    })
      .then(function (res) {
        setSubmitted(true);
        setBody("");
        setUserName("");
        fetchComments();
      })
      .catch(function (res) {});
  };
  const handleEditSubmit = async (commentId) => {
    //localhost:1337/api/comments/api::article.article:4/comment/1
    await fetch(
      ` https://admin.avtoe.news/api/comments/api::news.news:${id}/comment/${commentId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzE4OTc1NjgzLCJleHAiOjE3MjE1Njc2ODN9.ndHmLPd1ktovK28N1W9NZkMf7M9GQfiCD78QtrprY8E"
        },
        method: "PUT",
        body: JSON.stringify({
          content: editContent
        })
      }
    ).then(function (res) {
      setIsEditing(false);
      setIsSubmitted(true);
      setEditingCommentId(null);
      fetchComments();
    });
  };

  const handleRemove = async (commentID, authorId) => {
    const response = await fetch(
      `https://admin.avtoe.news/api/comments/api::news.news:${id}/comment/${commentID}?authorId=${authorId}`,
      {
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzE4OTc1NjgzLCJleHAiOjE3MjE1Njc2ODN9.ndHmLPd1ktovK28N1W9NZkMf7M9GQfiCD78QtrprY8E"
        },
        method: "DELETE"
      }
    );
    if (response.ok) {
      setCommentsData((prevComments) =>
        prevComments.filter((comment) => comment.id !== commentID)
      );
    } else {
      const errorResponse = await response.json();
      console.error(
        `Error: ${response.status} ${response.statusText} - ${errorResponse.message}`
      );
    }
  };
  useEffect(() => {
    fetchComments();
  }, []);

  return (
    <div className="animsition">
      <div className="bg0 p-t-70">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8 mt-5">
              <span className="cl8 d-flex justify-content-between">
                <a
                  href="/news"
                  className="f1-s-4 cl8 hov-cl10 trans-03 no-underline"
                >
                  Новини &rsaquo;{" "}
                  <span className="f1-s-3 no-underline">
                    {articles?.data.attributes.Date
                      ? `${new Date(
                          `${articles?.data.attributes.Date}`
                        ).toLocaleString("uk-UA", {
                          month: "short",
                          day: "numeric",
                          year: "numeric"
                        })}`
                      : ""}
                  </span>
                </a>
              </span>
              <h2 className="p-b-20 mt-4">
                {" "}
                {articles?.data.attributes.Title}
              </h2>

              <div className="p-b-20 mt-5">
                {" "}
                {articles?.data.attributes.Description}
                <div className="p-b-20 markdown">
                  <Markdown>{articles?.data.attributes.Body}</Markdown>
                </div>
                <hr></hr>
                <p>
                  Поділитись:
                  <FacebookShareButton
                    url={window.location.href}
                    className="ms-3"
                  >
                    <FacebookIcon size={30} round={true} />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={window.location.href}
                    className="ms-2"
                  >
                    <TwitterIcon size={30} round={true} />
                  </TwitterShareButton>
                  <TelegramShareButton
                    url={window.location.href}
                    className="ms-2"
                  >
                    <TelegramIcon size={30} round={true} />
                  </TelegramShareButton>
                </p>
              </div>
            </div>

            <div className="col-md-10 col-lg-4 mt-5">
              <Popular />
              <AvtoeApp />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="flex-c-c">
          <a href="#"></a>
        </div>
      </div>

      <div className="bg0 p-t-60 p-b-35">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8 p-b-20"></div>
            {/* <Latest /> */}

            <div className="col-md-10 col-lg-4">{/* <FeaturedVideo /> */}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsDetails;
