import React from "react";
import { useFetch } from "../../api/index";
import { Link } from "react-router-dom";
import { PulseLoader } from "react-spinners";

const ArticlesSmall = () => {
  const { articles, loading, error } = useFetch(
    "https://admin.avtoe.news/api/articles?populate=Img"
  );

  if (loading) return <PulseLoader color="#0a28b7" />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="tab-content p-0">
      <div className="tab-pane fade show active" id="tab1-1" role="tabpanel">
        <div className="how2 how2-cl4 flex-s-c m-r-10 m-r-0-sr991 justify-content-between mb-5">
          <h3 className="f1-m-2 cl2 tab01-title m-0">Останні статті</h3>

          <a href="/videos" className="tab01-link f1-s-1 cl9 hov-cl10 trans-03">
            Дивитись всі
            <i className="fs-12 m-l-5 fa fa-caret-right"></i>
          </a>
        </div>

        <div className="row">
          <div className="col-sm-12 p-r-25 p-r-15-sr991">
            {articles?.data.slice(0, 7).map((article) => {
              const imgURL = article.attributes.Img.data.attributes.url;
              return (
                <Link
                  className="link-custom"
                  to={`/article/${article.id}`}
                  key={article.id}
                >
                  <div className="flex-wr-sb-s m-b-30">
                    <a href="/" className="size-w-1 wrap-pic-w hov1 trans-03">
                      <img
                        src={`https://admin.avtoe.news${imgURL}`}
                        alt="IMG"
                      />
                    </a>

                    <div className="size-w-2">
                      <h5 className="m-0 p-0 mt-1">
                        <a href="/" className="f1-s-5 cl3 hov-cl10 trans-03">
                          {article.attributes.Title}
                        </a>
                      </h5>

                      <span className="cl8">
                        <a href="#" className="f1-s-6 cl8 hov-cl10 trans-03">
                          {article.attributes.Category}
                        </a>

                        <span className="f1-s-3 m-rl-3">-</span>

                        <span className="f1-s-3">
                          {" "}
                          {new Date(
                            `${article.attributes.createdAt}`
                          ).toLocaleString("uk-UA", {
                            month: "short",
                            day: "numeric",
                            year: "numeric"
                          })}
                        </span>
                      </span>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticlesSmall;
