import React from "react";
import Featured from "../Featured";
import Business from "../Business";
import Popular from "../Popular";
import Latest from "../Latest";
import Tags from "../Tags";
import banner from "../../images/banner-01.jpg";
import { useTheme } from "../../context/ThemeContext";

const Home = () => {
  const { theme, toggleTheme } = useTheme("light");

  return (
    <div
      // className={theme === "dark" ? "dark animsition " : "animsition font-dark"}
      className={`animsition ${theme === "dark" ? "dark-mode" : "light-mode"}`}
    >
      <Featured />
      <div className="bg0 p-t-70">
        <div className="container">
          <div className="row justify-content-center mobile-flex-reverse">
            <div className="col-md-10 col-lg-8">
              <div className="p-b-20">
                <Business />
              </div>
            </div>

            <div className="col-md-10 col-lg-4">
              <Popular />
              {/* <Subscribe /> */}
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="flex-c-c">
          <a href="#">
            <img className="max-w-full" src={banner} alt="IMG" />
            {/* РЕКЛАМА */}
          </a>
        </div>
      </div>

      <div className="bg0 p-t-60 p-b-35">
        <div className="container">
          <div className="row justify-content-center">
            <Latest />

            <div className="col-md-10 col-lg-4">
              <Tags />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
