import React from "react";
import ios from "../../images/ios-d.png";
import android from "../../images/android-d.png";

const AvtoeApp = () => {
  return (
    <div className="p-l-10 p-rl-0-sr991 p-b-20">
      <div>
        <div className="how2 how2-cl4 flex-s-c">
          <h3 className="f1-m-2 cl3 tab01-title m-0">Місце для реклами</h3>
        </div>

        <ul className="p-t-35 "></ul>
      </div>
    </div>
  );
};

export default AvtoeApp;
