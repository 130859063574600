import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { PulseLoader } from "react-spinners";
import { Media, Video } from "@vidstack/player-react";

const Videos = () => {
  const [videos, setVideos] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch("https://admin.avtoe.news/api/videos");

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        setVideos(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (error) return <div>Error: {error}</div>;

  return (
    <Row className="row p-t-35">
      {videos?.data?.map((item) => {
        if (loading) {
          return <PulseLoader color="#0a28b7" />;
        } else {
          return (
            <Col
              lg={4}
              md={6}
              sm={12}
              xs={12}
              className="p-r-25 p-r-15-sr991"
              key={item.attributes.id}
            >
              <iframe
                width="100%"
                src={item.attributes.link}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen="true"
              ></iframe>
            </Col>
          );
        }
      })}
    </Row>
  );
};

export default Videos;
