import "./App.scss";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/Header";
import Home from "./components/Home";
import Footer from "./components/Footer";
import ArticleDetails from "./components/ArticleDetails";
import NotFound from "./components/NotFound";
import AllArticles from "./components/AllArticles";
import AllNews from "./components/AllNews";
import AllVideos from "./components/AllVideos";
import { useTheme } from "./context/ThemeContext";
import NewsDetails from "./components/NewsDetails";
import ArticlesByCategory from "./components/ArticlesByCategory";

function App() {
  const { theme, toggleTheme } = useTheme("dark");
  const [isDarkMode, setDarkMode] = useState(theme === "dark");

  const toggleDarkMode = (checked) => {
    setDarkMode(checked);
    toggleTheme();
  };

  return (
    <div className={theme === "dark" ? "dark" : "light"}>
      <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/article/:id" element={<ArticleDetails />} />
          <Route path="/articles" element={<AllArticles />} />
          <Route path="/news" element={<AllNews />} />
          <Route path="/news/:id" element={<NewsDetails />} />
          <Route path="/videos" element={<AllVideos />} />
          <Route path="/articlesСategory" element={<ArticlesByCategory />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
