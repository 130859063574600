import React from "react";

const Tags = () => {
  return (
    <div>
      <div className="p-b-55">
        <div className="how2 how2-cl4 flex-s-c m-b-30">
          <h3 className="f1-m-2 cl3 tab01-title m-0">Теги</h3>
        </div>

        <div className="flex-wr-s-s m-rl--5">
          <a
            href="/news"
            className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
          >
            Новини
          </a>

          <a
            href="/articles"
            className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
          >
            Автобізнес
          </a>

          <a
            href="/videos"
            className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
          >
            YouTube
          </a>

          <a
            href="/articles"
            className="flex-c-c size-h-2 bo-1-rad-20 bocl12 f1-s-1 cl8 hov-btn2 trans-03 p-rl-20 p-tb-5 m-all-5"
          >
            Статті
          </a>
        </div>
      </div>
    </div>
  );
};

export default Tags;
