import React, { useState } from "react";

import News from "../News";

const Popular = () => {
  return (
    <div className="p-l-10 p-rl-0-sr991 p-b-20">
      <div>
        <div className="how2 how2-cl4 flex-s-c  justify-content-between">
          <h3 className="f1-m-2 cl3 tab01-title m-0">Останні новини</h3>

          <a href="/news" className="tab01-link f1-s-1 cl9 hov-cl10 trans-03">
            Дивитись всі
            <i className="fs-12 m-l-5 fa fa-caret-right"></i>
          </a>
        </div>

        <News />
      </div>
    </div>
  );
};

export default Popular;
