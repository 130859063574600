import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFetch } from "../../api/index";
import { Link } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { useTheme, theme } from "../../context/ThemeContext";

const Featured = () => {
  const { theme, toggleTheme } = useTheme("light");

  const { id } = useParams();
  const { articles, loading, error } = useFetch(
    "https://admin.avtoe.news/api/articles?populate=Img"
  );

  if (loading)
    return (
      <div className="loading-spinner">
        <PulseLoader color="#0a28b7" />
      </div>
    );
  if (error) return <div className="loading-spinner">Error: {error}</div>;

  return (
    <div
      className={
        theme === "dark" ? "dark bg0 margin-header" : "light bg0 margin-header"
      }
    >
      <div className="container">
        <div className="row ">
          {articles?.data.slice(0, 1).map((article) => {
            const imgURL = article.attributes.Img.data.attributes.url;
            return (
              <div
                className="col-md-6 p-1 mt-1 mobile-top-margin"
                key={article.id}
              >
                <Link className="link-custom" to={`/article/${article.id}`}>
                  <div
                    className="bg-img1 size-a-3 how1 pos-relative"
                    style={{
                      backgroundImage: `url(https://admin.avtoe.news${imgURL})`,
                      backgroundSize: "cover"
                    }}
                  >
                    <a href="/" className="dis-block how1-child1 trans-03"></a>

                    <div className="flex-col-e-s s-full p-rl-25 p-tb-20">
                      <Link
                        to={`articlesСategory?category=${article.attributes.Category}`}
                        href="#"
                        className="dis-block how1-child2 f1-s-2 cl0 bo-all-1 bocl0 hov-btn1 trans-03 p-rl-5 p-t-2"
                      >
                        {article.attributes.Category}
                      </Link>

                      <h3 className="how1-child2 m-t-4 m-b-10">
                        <a
                          href="/"
                          className="how-txt1 size-a-6 f1-l-2 cl0 hov-cl10 trans-0"
                        >
                          {article.attributes.Title}
                        </a>
                      </h3>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}

          <div className="col-md-6">
            <div className="row m-rl--1">
              {articles?.data.slice(1, 2).map((article) => {
                const imgURL = article.attributes.Img.data.attributes.url;
                return (
                  <div
                    className="col-12 p-1 p-b-2 mobile-none"
                    key={article.id}
                  >
                    <Link className="link-custom" to={`/article/${article.id}`}>
                      <div
                        className="bg-img1 size-a-4 how1 pos-relative"
                        style={{
                          backgroundImage: `url(https://admin.avtoe.news${imgURL})`,
                          backgroundSize: "cover"
                        }}
                      >
                        <a
                          href="/"
                          className="dis-block how1-child1 trans-03"
                        ></a>

                        <div className="flex-col-e-s s-full p-rl-25 p-tb-24">
                          <Link
                            to={`articlesСategory?category=${article.attributes.Category}`}
                            className="dis-block how1-child2  f1-s-2 cl0 bo-all-1 bocl0 hov-btn1 trans-03 p-rl-5 p-t-2"
                          >
                            {article.attributes.Category}
                          </Link>

                          <h3 className="how1-child2 m-t-4">
                            <a
                              href="/"
                              className="how-txt1 size-a-7 f1-m-2 cl0 hov-cl10 trans-03"
                            >
                              {article.attributes.Title}
                            </a>
                          </h3>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
              {articles?.data.slice(2, 4).map((article) => {
                const imgURL = article.attributes.Img.data.attributes.url;
                return (
                  <>
                    <div className="col-sm-6 p-1 mobile-none" key={article.id}>
                      <Link
                        className="link-custom"
                        to={`/article/${article.id}`}
                      >
                        <div
                          className="bg-img1 size-a-5 how1 pos-relative"
                          style={{
                            backgroundImage: `url(https://admin.avtoe.news${imgURL})`,
                            backgroundSize: "cover"
                          }}
                        >
                          <a
                            href="/"
                            className="dis-block how1-child1 trans-03"
                          ></a>

                          <div className="flex-col-e-s s-full p-rl-25 p-tb-20">
                            <Link
                              to={`articlesСategory?category=${article.attributes.Category}`}
                              className="dis-block how1-child2 f1-s-2 cl0 bo-all-1 bocl0 hov-btn1 trans-03 p-rl-5 p-t-2"
                            >
                              {article.attributes.Category}
                            </Link>

                            <h3 className="how1-child2 m-t-4">
                              <a
                                href="/"
                                className="how-txt1 size-h-1 f1-m-1 cl0 hov-cl10 trans-03"
                              >
                                {article.attributes.Title}
                              </a>
                            </h3>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </>
                );
              })}
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Featured;
