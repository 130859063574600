import React from "react";
import Popular from "../Popular";
import Subscribe from "../Subscribe";
import Videos from "../Videos";

const AllVideos = () => {
  return (
    <div className="animsition">
      <div className="bg0 p-t-70">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8">
              <div className="p-b-20">
                <Videos />
              </div>
            </div>

            <div className="col-md-10 col-lg-4 mt-5">
              <Popular />
              {/* <Subscribe /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllVideos;
