import React from "react";
import ArticlesSmall from "../ArticlesSmall";
import News from "../News";

const AllNews = () => {
  return (
    <div className="animsition">
      <div className="bg0 p-t-70">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8">
              <div className="p-b-20">
                <div className="align-center how2 how2-cl4 flex-s-c m-r-10 m-r-0-sr991 justify-content-between mt-5">
                  <h3 className="f1-m-2 cl3  ">Останні новини</h3>
                </div>
                <News />
              </div>
            </div>

            <div className="col-md-10 col-lg-4 mt-5">
              <ArticlesSmall />
              {/* <Subscribe /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllNews;
