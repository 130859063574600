import React from "react";
import { useFetch } from "../../api/index";
import { Link } from "react-router-dom";
import moment from "moment";
import { PulseLoader } from "react-spinners";
import { useTheme } from "../../context/ThemeContext";

const News = () => {
  const { theme, toggleDark } = useTheme();
  const { articles, loading, error } = useFetch(
    "https://admin.avtoe.news/api/news?populate=Img"
  );

  if (loading) return <PulseLoader color="#0a28b7" />;
  if (error) return <div>Error: {error}</div>;

  return (
    <ul className={theme === "dark" ? "dark p-t-35" : "light bg0 p-t-35"}>
      {articles.data.map((article, index) => {
        return (
          <>
            {/* {console.log(articles, "artilces")} */}
            <Link
              className="link-custom"
              to={`/news/${article.id}`}
              key={article.id}
            >
              <div key={index}>
                <li className="flex-wr-sb-s p-b-22">
                  <span>
                    {moment(article.attributes.Date).format("HH:mm:ss")}

                    <a
                      href="#"
                      className="ms-3 size-w-3 f1-s-7 cl3 hov-cl10 trans-03"
                    >
                      {article.attributes.Title}
                    </a>
                  </span>{" "}
                </li>
              </div>
            </Link>
          </>
        );
      })}
    </ul>
  );
};

export default News;
