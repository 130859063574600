import React from "react";
import arrowTop from "../../images/arrow-top.png";
import logo from "../../images/avtoe-bg.png";
import { SocialIcon } from "react-social-icons";
import apple from "../../images/apple.webp";
import android from "../../images/android.png";
import { MdLocalPhone } from "react-icons/md";
import { IoMdMail } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";

const Footer = () => {
  const handleTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="footer">
      <div className="animsition">
        <div className="bg2 p-t-20">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 p-b-20 center-mobile mobile-flex mt-3">
                <a>
                  <img
                    className="max-s-full logo-footer"
                    src={logo}
                    alt="LOGO"
                    width={100}
                    // height={"100%"}
                  />{" "}
                </a>
              </div>

              <div className="col-lg-5  p-b-20 center-mobile footer mobile-flex d-flex align-items-center">
                <ul className="list-unstyled mt-3">
                  <div className="d-flex justify-content-center mb-2 align-center">
                    <li className="d-flex align-center">
                      <span
                        className="left-topbar-item"
                        style={{ fontSize: "14px" }}
                      >
                        <MdLocalPhone size={15} /> +38 (050) 24-90-909
                      </span>
                    </li>
                    <li className="d-flex align-center">
                      <span
                        className="left-topbar-item"
                        style={{ fontSize: "14px" }}
                      >
                        <IoMdMail size={15} /> avtoe@i.ua
                      </span>
                    </li>
                  </div>
                  <li className="d-flex align-center">
                    <span
                      className="left-topbar-item"
                      style={{ fontSize: "15px" }}
                    >
                      <FaLocationDot size={15} /> м.Київ вул. Метробудівська
                      буд.12 офіс 57
                    </span>
                  </li>
                </ul>
              </div>

              <div className="col-lg-4 p-b-20 mobile-flex">
                <div className="size-h-3 flex-s-c social-mobile">
                  <li className="right-topbar">
                    <a href="#">
                      <SocialIcon
                        url="https://www.youtube.com/@user-so1jb9wi3t"
                        style={{ width: "30px", height: "30px" }}
                      />
                    </a>
                    <a href="#">
                      <SocialIcon
                        url="https://www.facebook.com/avtoeukraine/"
                        style={{ width: "30px", height: "30px" }}
                      />
                    </a>
                    <a href="#">
                      <SocialIcon
                        url="https://www.instagram.com/avtoe.ua/"
                        style={{ width: "30px", height: "30px" }}
                      />
                    </a>
                    {/* <a href="#">
                      <SocialIcon url="https://telegram.com" width="100" />
                    </a> */}
                    <a href="https://apps.apple.com/ua/app/avtoe/id6450632591">
                      <img src={apple} width="30" className="me-2 ms-2" />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.avtoe&hl=uk&gl=US">
                      <img src={android} width="30" />
                    </a>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg11">
          <div className="container size-h-4 flex-c-c p-tb-15">
            <span className="f1-s-1 cl0 txt-center">Avtoe.News © 2024</span>
          </div>
        </div>

        <div className="btn-back-to-top" id="myBtn" onClick={handleTop}>
          <span className="symbol-btn-back-to-top">
            <img src={arrowTop} alt="" width={10} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
