import React from "react";
import { useFetch } from "../../api/index";
import { Link } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { useTheme } from "../../context/ThemeContext";

const Articles = () => {
  const { theme, toggleTheme } = useTheme("light");

  const { articles, loading, error } = useFetch(
    "https://admin.avtoe.news/api/articles?populate=Img"
  );

  if (loading) return <PulseLoader color="#0a28b7" />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="tab-content p-t-35">
      <div className="tab-pane fade show active" id="tab1-1" role="tabpanel">
        <div className="row">
          {articles?.data.slice(2, 7).map((article) => {
            const imgURL = article.attributes.Img.data.attributes.url;
            return (
              <div
                key={article.attributes.id}
                className="col-sm-6 p-r-25 p-r-15-sr991"
              >
                <div className="m-b-30">
                  <a
                    href={`/article/${article.id}`}
                    className="wrap-pic-w hov1 trans-03"
                  >
                    <img src={`https://admin.avtoe.news${imgURL}`} alt="IMG" />
                  </a>

                  <div className="">
                    <h5 className="m-0 p-0 mt-2">
                      <a
                        href={`/article/${article.id}`}
                        className="f1-m-3 cl2 hov-cl10 trans-03"
                      >
                        {article.attributes.Title}
                      </a>
                    </h5>

                    <span className="cl8">
                      {/* <a href="#" className="f1-s-4 cl8 hov-cl10 trans-03">
                        {article.attributes.Tags}
                      </a>

                      <span className="f1-s-3 m-rl-3">-</span> */}

                      <span className="f1-s-3">
                        {new Date(
                          `${article.attributes.createdAt}`
                        ).toLocaleString("uk-UA", {
                          month: "short",
                          day: "numeric",
                          year: "numeric"
                        })}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            );
          })}

          <div className="col-sm-6 p-r-25 p-r-15-sr991">
            {articles?.data.slice(4, 7).map((article) => {
              const imgURL = article.attributes.Img.data.attributes.url;
              return (
                <Link
                  className="link-custom"
                  to={`/article/${article.id}`}
                  key={article.id}
                >
                  <div className="flex-wr-sb-s m-b-30">
                    <a
                      href={`/article/${article.id}`}
                      className="size-w-1 wrap-pic-w hov1 trans-03"
                    >
                      <img
                        src={`https://admin.avtoe.news${imgURL}`}
                        alt="IMG"
                      />
                    </a>

                    <div className="size-w-2">
                      <h5 className="m-0 p-0">
                        <a href="/" className="f1-s-5 cl3 hov-cl10 trans-03">
                          {article.attributes.Title}
                        </a>
                      </h5>

                      <span>
                        {/* <a href="#" className="f1-s-6 cl8 hov-cl10 trans-03">
                          {article.attributes.Category}
                        </a>

                        <span className="f1-s-3 m-rl-3">-</span> */}

                        <span className="f1-s-3 m-0 p-0">
                          {" "}
                          {new Date(
                            `${article.attributes.createdAt}`
                          ).toLocaleString("ua-UA", {
                            month: "short",
                            day: "numeric",
                            year: "numeric"
                          })}
                        </span>
                      </span>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Articles;
