import React from "react";
import Articles from "../Articles";

const Business = () => {
  return (
    <>
      <div className="tab01 p-b-20">
        <div className="how2 how2-cl4 flex-s-c m-r-10 m-r-0-sr991 justify-content-between">
          <h3 className="f1-m-2 cl2 tab01-title m-0">Популярні статті</h3>

          <a
            href="/articles"
            className="tab01-link f1-s-1 cl9 hov-cl10 trans-03"
          >
            Дивитись всі
            <i className="fs-12 m-l-5 fa fa-caret-right"></i>
          </a>
        </div>

        <Articles />
      </div>
    </>
  );
};

export default Business;
