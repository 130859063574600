import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { PulseLoader } from "react-spinners";
import { useTheme } from "../../context/ThemeContext";

const ArticlesByCategory = () => {
  const { theme } = useTheme("light");

  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const category = query.get("category");

  useEffect(() => {
    fetchArticles();
  }, [category]);

  const fetchArticles = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://admin.avtoe.news/api/articles?filters[Category][$eq]=${category}&populate=*`
      );
      setArticles(response.data);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  if (loading) return <PulseLoader color="#0a28b7" />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div
      className={
        theme === "dark" ? "dark bg0 margin-header" : "light bg0 margin-header"
      }
    >
      <div className="container">
        {/* <h1>Articles in {category}</h1> */}
        <div className="row">
          {articles?.data.slice(2, 7).map((article) => {
            const imgURL = article.attributes.Img.data.attributes.url;
            return (
              <div key={article.id} className="col-sm-6 p-r-25 p-r-15-sr991">
                <div className="m-b-30">
                  <Link
                    to={`/article/${article.id}`}
                    className="wrap-pic-w hov1 trans-03"
                  >
                    <img src={`https://admin.avtoe.news${imgURL}`} alt="IMG" />
                  </Link>
                  <div className="p-t-20">
                    <h5 className="p-b-5">
                      <Link
                        to={`/article/${article.id}`}
                        className="f1-m-3 cl2 hov-cl10 trans-03"
                      >
                        {article.attributes.Title}
                      </Link>
                    </h5>
                    <span className="cl8">
                      {/* <Link
                        to={`/articles?category=${article.attributes.Category}`}
                        className="f1-s-4 cl8 hov-cl10 trans-03"
                      >
                        {article.attributes.Tags}
                      </Link> */}
                      {/* <span className="f1-s-3 m-rl-3">-</span> */}
                      <span className="f1-s-3">
                        {new Date(article.attributes.createdAt).toLocaleString(
                          "uk-UA",
                          {
                            month: "short",
                            day: "numeric",
                            year: "numeric"
                          }
                        )}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="col-sm-6 p-r-25 p-r-15-sr991">
            {articles?.data.slice(4, 7).map((article) => {
              const imgURL = article.attributes.Img.data.attributes.url;
              return (
                <Link
                  className="link-custom"
                  to={`/article/${article.id}`}
                  key={article.id}
                >
                  <div className="flex-wr-sb-s m-b-30">
                    <Link
                      to={`/article/${article.id}`}
                      className="size-w-1 wrap-pic-w hov1 trans-03"
                    >
                      <img
                        src={`https://admin.avtoe.news${imgURL}`}
                        alt="IMG"
                      />
                    </Link>
                    <div className="size-w-2">
                      <h5 className="p-b-5">
                        <Link
                          to={`/article/${article.id}`}
                          className="f1-s-5 cl3 hov-cl10 trans-03"
                        >
                          {article.attributes.Title}
                        </Link>
                      </h5>
                      <span className="cl8">
                        {/* <Link
                          to={`/articles?category=${article.attributes.Category}`}
                          className="f1-s-6 cl8 hov-cl10 trans-03"
                        >
                          {article.attributes.Category}
                        </Link>
                        <span className="f1-s-3 m-rl-3">-</span> */}
                        <span className="f1-s-3">
                          {new Date(
                            article.attributes.createdAt
                          ).toLocaleString("uk-UA", {
                            month: "short",
                            day: "numeric",
                            year: "numeric"
                          })}
                        </span>
                      </span>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticlesByCategory;
